import Badge from '@material-ui/core/Badge';
import Icon from "@theme/icons/icon";

const WithoutLink = ({ cartData = 0 }) => (
    <Badge color="secondary" badgeContent={cartData || 0}>
        <Icon icon="cart" size={24} />
    </Badge>
);

export default WithoutLink;
